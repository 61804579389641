import { GrowthBook } from '@growthbook/growthbook-react';

import config from 'config';

const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: config.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: config.ENV === 'stage',
  disableCache: true,
});

gb.init();

export default gb;
