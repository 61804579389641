export const DEFAULT_FLOW_PROJECT_NAME = 'femia';
export const DEFAULT_FLOW = 'DEFAULT_FLOW';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW_TC = {
  branch_name: 'default',
  flow_name: 'femia__default__default__client',
  start_page: {
    id: 'ob_intro_tc',
    name: 'ob_intro_tc',
    config: {},
  },
  onboarding_flow: [
    {
      id: 'ob_birth_year',
      name: 'ob_birth_year',
      config: {},
    },
    {
      id: 'ob_cycle_regularity',
      name: 'ob_cycle_regularity',
      config: {},
    },
    {
      id: 'ob_mood_swings',
      name: 'ob_mood_swings',
      config: {},
    },
    {
      id: 'ob_experienced_symptoms',
      name: 'ob_experienced_symptoms',
      config: {},
    },
    {
      id: 'ob_mental_health',
      name: 'ob_mental_health',
      config: {},
    },
    {
      id: 'ob_sex_issues',
      name: 'ob_sex_issues',
      config: {},
    },
    {
      id: 'ob_birth_control',
      name: 'ob_birth_control',
      config: {},
    },
    {
      id: 'ob_health_problems',
      name: 'ob_health_problems',
      config: {},
    },
    {
      id: 'ob_period_duration',
      name: 'ob_period_duration',
      config: {},
    },
    {
      id: 'ob_cycle_duration',
      name: 'ob_cycle_duration',
      config: {},
    },
    {
      id: 'ob_plan_creation_steps',
      name: 'ob_plan_creation_steps',
      config: {},
    },
    {
      id: 'ob_enter_email',
      name: 'ob_enter_email',
      config: {},
    },
  ],
  payment_flow: [
    {
      id: 'payment',
      name: 'payment_fl2_7d',
      parent_id: null,
      is_paid: null,
      skip_checkout: false,
      products: [
        {
          payment_type: 'subscription',
          id: '74615bb9-2181-4fc9-8585-65de9e607b03',
          name: 'One-week intro (1.00) + One-month (24.99)',
          trial: 7,
          period: 30,
          product_code: 'app_full_access',
          is_preselected: true,
          product_state: 'per_month',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 100,
          start_price: 100,
          price: 100,
          sign: '$',
          code: 'USD',
          ltv: 2000,
        },
        {
          payment_type: 'subscription',
          id: 'a58e5872-734d-416a-9d62-fda347497bed',
          name: 'One-month intro (24.99) + One-month (24.99)',
          trial: 30,
          period: 30,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_month',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 2499,
          price_per_state: 2499,
          start_price: 2499,
          price: 2499,
          sign: '$',
          code: 'USD',
          ltv: 4403,
        },
      ],
      config: {},
    },
  ],
  expire: 1633705410444,
};

export const DEFAULT_FLOW_GP = {
  branch_name: 'default',
  flow_name: 'femia_gp__default__default__client',
  start_page: {
    id: 'ob_choose_us_ct_start',
    name: 'ob_choose_us_ct_start',
    config: {},
  },
  onboarding_flow: [
    {
      id: 'ob_gp_subgoal_ct',
      name: 'ob_gp_subgoal_ct',
      config: {},
    },
    {
      id: 'ob_first_time',
      name: 'ob_first_time',
      config: {},
    },
    {
      id: 'ob_social_proof_ct',
      name: 'ob_social_proof_ct',
      config: {},
    },
    {
      id: 'ob_when_baby',
      name: 'ob_when_baby',
      config: {},
    },
    {
      id: 'ob_children_number_v2',
      name: 'ob_children_number_v2',
      config: {},
    },
    {
      id: 'ob_feel_reset',
      name: 'ob_feel_reset',
      config: {},
    },
    {
      id: 'ob_job_description',
      name: 'ob_job_description',
      config: {},
    },
    {
      id: 'ob_back_difficulties',
      name: 'ob_back_difficulties',
      config: {},
    },
    {
      id: 'ob_tend_to_drink',
      name: 'ob_tend_to_drink',
      config: {},
    },
    {
      id: 'ob_eat_habits',
      name: 'ob_eat_habits',
      config: {},
    },
    {
      id: 'ob_sugar_snacks',
      name: 'ob_sugar_snacks',
      config: {},
    },
    {
      id: 'ob_skins_glow',
      name: 'ob_skins_glow',
      config: {},
    },
    {
      id: 'ob_sunshine',
      name: 'ob_sunshine',
      config: {},
    },
    {
      id: 'ob_air_polution',
      name: 'ob_air_polution',
      config: {},
    },
    {
      id: 'ob_birth_year_ct',
      name: 'ob_birth_year_ct',
      config: {},
    },
    {
      id: 'ob_bio_age_intro_ct',
      name: 'ob_bio_age_intro_ct',
      config: {},
    },
    {
      id: 'ob_bio_age_weight',
      name: 'ob_bio_age_weight',
      config: {},
    },
    {
      id: 'ob_bio_age_hair',
      name: 'ob_bio_age_hair',
      config: {},
    },
    {
      id: 'ob_bio_age_energy',
      name: 'ob_bio_age_energy',
      config: {},
    },
    {
      id: 'ob_bio_energy_meals',
      name: 'ob_bio_energy_meals',
      config: {},
    },
    {
      id: 'ob_state_ct',
      name: 'ob_state_ct',
      config: {},
    },
    {
      id: 'ob_describe_pl',
      name: 'ob_describe_pl',
      config: {},
    },
    {
      id: 'ob_different_o',
      name: 'ob_different_o',
      config: {},
    },
    {
      id: 'ob_kegel',
      name: 'ob_kegel',
      config: {},
    },
    {
      id: 'ob_partner_feel',
      name: 'ob_partner_feel',
      config: {},
    },
    {
      id: 'ob_partner_habits',
      name: 'ob_partner_habits',
      config: {},
    },
    {
      id: 'ob_partner_optimize',
      name: 'ob_partner_optimize',
      config: {},
    },
    {
      id: 'ob_gn_loading_ct',
      name: 'ob_gn_loading_ct',
      config: {},
    },
    {
      id: 'ob_gn_gpfaster_1_ct',
      name: 'ob_gn_gpfaster_1_ct',
      config: {},
    },
    {
      id: 'ob_business',
      name: 'ob_business',
      config: {},
    },
    {
      id: 'ob_exercise_frequancy',
      name: 'ob_exercise_frequancy',
      config: {},
    },
    {
      id: 'ob_connected_with_body',
      name: 'ob_connected_with_body',
      config: {},
    },
    {
      id: 'ob_response_situations',
      name: 'ob_response_situations',
      config: {},
    },
    {
      id: 'ob_sleep_describe',
      name: 'ob_sleep_describe',
      config: {},
    },
    {
      id: 'ob_wakeup_energized',
      name: 'ob_wakeup_energized',
      config: {},
    },
    {
      id: 'ob_mood_emotions',
      name: 'ob_mood_emotions',
      config: {},
    },
    {
      id: 'ob_prioritize_relaxation',
      name: 'ob_prioritize_relaxation',
      config: {},
    },
    {
      id: 'ob_gn_gpfaster_2_ct',
      name: 'ob_gn_gpfaster_2_ct',
      config: {},
    },
    {
      id: 'ob_solutions',
      name: 'ob_solutions',
      config: {},
    },
    {
      id: 'ob_gn_state_of_mind_ct',
      name: 'ob_gn_state_of_mind_ct',
      config: {},
    },
    {
      id: 'ob_gn_confidence_ct',
      name: 'ob_gn_confidence_ct',
      config: {},
    },
    {
      id: 'ob_gn_uncertain_ct',
      name: 'ob_gn_uncertain_ct',
      config: {},
    },
    {
      id: 'ob_gn_pace',
      name: 'ob_gn_pace',
      config: {},
    },
    {
      id: 'ob_professional',
      name: 'ob_professional',
      config: {},
    },
    {
      id: 'ob_glad_ct',
      name: 'ob_glad_ct',
      config: {},
    },
    {
      id: 'ob_plan_creation_ct',
      name: 'ob_plan_creation_ct',
      config: {},
    },
    {
      id: 'ob_enter_email_making',
      name: 'ob_enter_email_making',
      config: {},
    },
    {
      id: 'ob_email_marketing',
      name: 'ob_email_marketing',
      config: {},
    },
    {
      id: 'ob_before_after_ct',
      name: 'ob_before_after_ct',
      config: {},
    },
    {
      id: 'ob_save_money_ct',
      name: 'ob_save_money_ct',
      config: {},
    },
    {
      id: 'ob_welcome_ct',
      name: 'ob_welcome_ct',
      config: {},
    },
  ],
  payment_flow: [
    {
      'id': 'payment_resub_ct',
      'name': 'payment_resub_ct',
      'parent_id': null,
      'is_paid': null,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'subscription',
          'id': '16d3ec5a-8739-47cf-99ad-f1b0dca674aa',
          'name': 'One-month intro (8.99) + One-month (30.99) + Payment P5 LATAM',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 100,
          'price_per_state': 29,
          'start_price': 3099,
          'price': 899,
          'sign': '$',
          'code': 'USD',
          'ltv': 2312,
        },
        {
          'payment_type': 'subscription',
          'id': '2d9aaf1b-d330-4d07-94bb-f5ee8c2d46a7',
          'name': 'Three-month intro (17.99) + Three-month (53.19) + Payment P5 LATAM',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 59,
          'price_per_state': 20,
          'start_price': 5319,
          'price': 1799,
          'sign': '$',
          'code': 'USD',
          'ltv': 3190,
        },
        {
          'payment_type': 'subscription',
          'id': '83859435-3784-469e-a444-fb18f11e2524',
          'name': 'Six-month intro (29.99) + Six-month (74.99) - LATAM',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 41,
          'price_per_state': 16,
          'start_price': 7499,
          'price': 2999,
          'sign': '$',
          'code': 'USD',
          'ltv': 3787,
        },
        {
          'payment_type': 'subscription',
          'id': '834358dc-bf8a-4658-bac9-97144ef4ecb4',
          'name': 'One-month intro (30.99) + One-month (30.99) + Timer P5 LATAM',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 100,
          'price_per_state': 100,
          'start_price': 3099,
          'price': 3099,
          'sign': '$',
          'code': 'USD',
          'ltv': 3692,
        },
        {
          'payment_type': 'subscription',
          'id': '7d9254fb-33b0-430f-b3a8-e2af57d93f8a',
          'name': 'Three-month intro (53.19) + Three-month (53.19) + Payment P5 LATAM',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 59,
          'price_per_state': 59,
          'start_price': 5319,
          'price': 5319,
          'sign': '$',
          'code': 'USD',
          'ltv': 5452,
        },
        {
          'payment_type': 'subscription',
          'id': '770c14fc-a42b-4680-aae7-209d4f12fed1',
          'name': 'Six-month intro (74.99) + Six-month (74.99) - LATAM',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 41,
          'price_per_state': 41,
          'start_price': 7499,
          'price': 7499,
          'sign': '$',
          'code': 'USD',
          'ltv': 6764,
        },
        {
          'payment_type': 'subscription',
          'id': 'd8939b06-6648-46c7-86fe-0c5df60ed569',
          'name': 'One-month intro (15.19) + One-month (38.95)',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 126,
          'price_per_state': 49,
          'start_price': 3895,
          'price': 1519,
          'sign': '$',
          'code': 'USD',
          'ltv': 4084,
        },
        {
          'payment_type': 'subscription',
          'id': '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
          'name': 'Three-month intro (25.99) + Three-month (66.65)',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 29,
          'start_price': 6665,
          'price': 2599,
          'sign': '$',
          'code': 'USD',
          'ltv': 5684,
        },
        {
          'payment_type': 'subscription',
          'id': 'c8a34068-08fc-4fdd-8e9b-fa8f04a05007',
          'name': 'Six-month intro (44.99) + Six-month (89.99)',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 49,
          'price_per_state': 25,
          'start_price': 8999,
          'price': 4499,
          'sign': '$',
          'code': 'USD',
          'ltv': 5843,
        },
        {
          'payment_type': 'subscription',
          'id': '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
          'name': 'One-month intro (38.95) + One-month (38.95)',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 126,
          'price_per_state': 126,
          'start_price': 3895,
          'price': 3895,
          'sign': '$',
          'code': 'USD',
          'ltv': 6242,
        },
        {
          'payment_type': 'subscription',
          'id': '8eecec35-782b-40e4-807b-ae5a58d121d2',
          'name': 'Three-month intro (66.65) + Three-month (66.65) gl',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 74,
          'start_price': 6665,
          'price': 6665,
          'sign': '$',
          'code': 'USD',
          'ltv': 8777,
        },
        {
          'payment_type': 'subscription',
          'id': 'c9ececab-82ba-4da4-bb54-59d2c0369988',
          'name': 'Six-month intro (89.99) + Six-month (89.99)',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 49,
          'price_per_state': 49,
          'start_price': 8999,
          'price': 8999,
          'sign': '$',
          'code': 'USD',
          'ltv': 8766,
        },
        {
          'payment_type': 'subscription',
          'id': 'fc757084-eedc-4319-a3c8-02e34a7ec29a',
          'name': 'Three-month intro (10.80) + Three-month (66.65)',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 12,
          'start_price': 6665,
          'price': 1080,
          'sign': '$',
          'code': 'USD',
          'ltv': 4588,
        },
      ],
      'config': {
        'popup': false,
        'is_resubscribe_popup': false,
      },
    },
    {
      'id': 'upsell_solo_new',
      'name': 'upsell_solo_new',
      'parent_id': 'payment_resub_ct',
      'is_paid': true,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'lifetime',
          'id': 'upsell_lifetime_48',
          'name': 'upsell_lifetime_48',
          'trial': 0,
          'period': 1,
          'product_code': 'upsell',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 4800,
          'price_per_state': 4800,
          'start_price': 4800,
          'price': 4800,
          'sign': '$',
          'code': 'USD',
          'ltv': 3463,
        },
      ],
      'config': {},
    },
    {
      'id': 'payment_resub_ct_discount',
      'name': 'payment_resub_ct_discount',
      'parent_id': 'payment_resub_ct',
      'is_paid': false,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'subscription',
          'id': '6f9425d6-2eb5-4e08-9c00-f6397e943797',
          'name': 'One-month intro (6.99) + One-month (30.99) + Offer P5 LATAM',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 100,
          'price_per_state': 23,
          'start_price': 3099,
          'price': 699,
          'sign': '$',
          'code': 'USD',
          'ltv': 1835,
        },
        {
          'payment_type': 'subscription',
          'id': '0672041b-b568-4053-96cf-e975af82532d',
          'name': 'Three-month intro (13.99) + Three-month (53.19) + Offer P5 LATAM',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 59,
          'price_per_state': 16,
          'start_price': 5319,
          'price': 1399,
          'sign': '$',
          'code': 'USD',
          'ltv': 2623,
        },
        {
          'payment_type': 'subscription',
          'id': '15e493af-e30f-4be1-a444-249499acddb3',
          'name': 'Six-month intro (24.99) + Six-month (74.99) - LATAM',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 41,
          'price_per_state': 14,
          'start_price': 7499,
          'price': 2499,
          'sign': '$',
          'code': 'USD',
          'ltv': 3156,
        },
        {
          'payment_type': 'subscription',
          'id': '834358dc-bf8a-4658-bac9-97144ef4ecb4',
          'name': 'One-month intro (30.99) + One-month (30.99) + Timer P5 LATAM',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 100,
          'price_per_state': 100,
          'start_price': 3099,
          'price': 3099,
          'sign': '$',
          'code': 'USD',
          'ltv': 3692,
        },
        {
          'payment_type': 'subscription',
          'id': '7d9254fb-33b0-430f-b3a8-e2af57d93f8a',
          'name': 'Three-month intro (53.19) + Three-month (53.19) + Payment P5 LATAM',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 59,
          'price_per_state': 59,
          'start_price': 5319,
          'price': 5319,
          'sign': '$',
          'code': 'USD',
          'ltv': 5452,
        },
        {
          'payment_type': 'subscription',
          'id': '770c14fc-a42b-4680-aae7-209d4f12fed1',
          'name': 'Six-month intro (74.99) + Six-month (74.99) - LATAM',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 41,
          'price_per_state': 41,
          'start_price': 7499,
          'price': 7499,
          'sign': '$',
          'code': 'USD',
          'ltv': 6764,
        },
        {
          'payment_type': 'subscription',
          'id': '2efa5e56-620d-4c77-b691-8547ce397960',
          'name': 'One-month intro (11.29) + One-month (38.95)',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 126,
          'price_per_state': 36,
          'start_price': 3895,
          'price': 1129,
          'sign': '$',
          'code': 'USD',
          'ltv': 3559,
        },
        {
          'payment_type': 'subscription',
          'id': '4884b8ac-b541-4bdc-94c7-139521f63ac6',
          'name': 'Three-month intro (19.29) + Three-month (66.65)',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 21,
          'start_price': 6665,
          'price': 1929,
          'sign': '$',
          'code': 'USD',
          'ltv': 4725,
        },
        {
          'payment_type': 'subscription',
          'id': 'b2f16c11-9637-421c-bbf1-598901895e3f',
          'name': 'Six-month intro (35.99) + Six-month (89.99)',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 49,
          'price_per_state': 20,
          'start_price': 8999,
          'price': 3599,
          'sign': '$',
          'code': 'USD',
          'ltv': 4869,
        },
        {
          'payment_type': 'subscription',
          'id': '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
          'name': 'One-month intro (38.95) + One-month (38.95)',
          'trial': 31,
          'period': 31,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 126,
          'price_per_state': 126,
          'start_price': 3895,
          'price': 3895,
          'sign': '$',
          'code': 'USD',
          'ltv': 6242,
        },
        {
          'payment_type': 'subscription',
          'id': '8eecec35-782b-40e4-807b-ae5a58d121d2',
          'name': 'Three-month intro (66.65) + Three-month (66.65) gl',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 74,
          'start_price': 6665,
          'price': 6665,
          'sign': '$',
          'code': 'USD',
          'ltv': 8777,
        },
        {
          'payment_type': 'subscription',
          'id': 'c9ececab-82ba-4da4-bb54-59d2c0369988',
          'name': 'Six-month intro (89.99) + Six-month (89.99)',
          'trial': 182,
          'period': 182,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 49,
          'price_per_state': 49,
          'start_price': 8999,
          'price': 8999,
          'sign': '$',
          'code': 'USD',
          'ltv': 8766,
        },
        {
          'payment_type': 'subscription',
          'id': 'e81e2eb2-5f75-43ee-a52a-8638c39092d5',
          'name': 'Three-month intro (7.99) + Three-month (66.65)',
          'trial': 90,
          'period': 90,
          'product_code': 'app_full_access',
          'is_preselected': false,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 74,
          'price_per_state': 9,
          'start_price': 6665,
          'price': 799,
          'sign': '$',
          'code': 'USD',
          'ltv': 3909,
        },
      ],
      'config': {
        'is_resubscribe_popup': false,
      },
    },
    {
      'id': 'upsell_solo_new_discount',
      'name': 'upsell_solo_new_discount',
      'parent_id': 'upsell_solo_new',
      'is_paid': false,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'lifetime',
          'id': 'upsell_lifetime_36',
          'name': 'upsell_lifetime_36',
          'trial': 0,
          'period': 1,
          'product_code': 'upsell',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 3600,
          'price_per_state': 3600,
          'start_price': 3600,
          'price': 3600,
          'sign': '$',
          'code': 'USD',
          'ltv': 2598,
        },
      ],
      'config': {},
    },
    {
      'id': 'upsell_solo_new',
      'name': 'upsell_solo_new',
      'parent_id': 'payment_resub_ct_discount',
      'is_paid': true,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'lifetime',
          'id': 'upsell_lifetime_48',
          'name': 'upsell_lifetime_48',
          'trial': 0,
          'period': 1,
          'product_code': 'upsell',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 4800,
          'price_per_state': 4800,
          'start_price': 4800,
          'price': 4800,
          'sign': '$',
          'code': 'USD',
          'ltv': 3463,
        },
      ],
      'config': {},
    },
    {
      'id': 'upsell_solo_new_discount',
      'name': 'upsell_solo_new_discount',
      'parent_id': 'upsell_solo_new',
      'is_paid': false,
      'skip_checkout': false,
      'products': [
        {
          'payment_type': 'lifetime',
          'id': 'upsell_lifetime_36',
          'name': 'upsell_lifetime_36',
          'trial': 0,
          'period': 1,
          'product_code': 'upsell',
          'is_preselected': true,
          'product_state': 'per_day',
          'processor': 'solid',
          'location': 'UA',
          'start_price_per_state': 3600,
          'price_per_state': 3600,
          'start_price': 3600,
          'price': 3600,
          'sign': '$',
          'code': 'USD',
          'ltv': 2598,
        },
      ],
      'config': {},
    },
  ],
  expire: 1633705410444,
};
