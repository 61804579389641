import React, { ReactNode } from 'react';
import { GrowthBookProvider as GrowthbookProvider } from '@growthbook/growthbook-react';

import { gb } from 'services/GrowthBook';

interface GrowthBookProviderProps {
  children: ReactNode;
}

export const GrowthBookProvider = ({ children }: GrowthBookProviderProps) => {

  return <GrowthbookProvider growthbook={gb}>{children}</GrowthbookProvider>;
};
